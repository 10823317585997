/* eslint-disable camelcase, max-len */
import styled from '@emotion/styled'
import PropTypes from 'prop-types'

import { withErrorBoundary } from '@hmn/rtl-web-core/components/ErrorBoundary/ErrorBoundary.component'
import { useInnerHtml } from '@hmn/rtl-web-core/hooks'

import { Button } from '../../../Button'
import { HouseIcon, Icon, SearchIcon } from '../../../Icon'
import styles from './Head.style'

const HeadStyled = styled.section(props => ({ ...styles(props) }))

function Head({
    className,
    title,
    titleTagComponent: TitleTag,
    lead,
    buttonHomepageTitle,
    buttonHomepageLink,
    buttonHomepageAction,
    buttonSearchTitle,
    buttonSearchLink,
    buttonSearchAction,
    ...rest
}) {
    const titleHtmlProps = useInnerHtml(title)
    const leadHtmlProps = useInnerHtml(lead)

    return (
        <HeadStyled className={className} {...rest}>
            <div className="errorPageHead">
                {title && <TitleTag className="errorPageHead_title" {...titleHtmlProps} />}
                {lead && <p className="errorPageHead_lead" {...leadHtmlProps} />}
                {(buttonSearchTitle || buttonHomepageTitle) && (
                    <div className="errorPageHead_buttons">
                        {buttonHomepageTitle && (
                            <Button
                                title={buttonHomepageTitle}
                                href={buttonHomepageLink} // @TODO: Revise hrefs when real data is implemented
                                hrefAs={buttonHomepageLink}
                                onClick={buttonHomepageAction}
                                buttonWidthMobile="20%"
                                className="errorPageHead_button"
                                iconComponent={
                                    <Icon className="errorPageHead_button_icon" icon={HouseIcon} viewBox="0 0 16 16" />
                                }>
                                {buttonHomepageTitle}
                            </Button>
                        )}
                        {buttonSearchTitle && (
                            <Button
                                title={buttonSearchTitle}
                                href={buttonSearchLink}
                                hrefAs={buttonSearchLink}
                                onClick={buttonSearchAction}
                                buttonWidthMobile="20%"
                                className="errorPageHead_button"
                                iconComponent={
                                    <Icon className="errorPageHead_button_icon" icon={SearchIcon} viewBox="0 0 32 32" />
                                }>
                                {buttonSearchTitle}
                            </Button>
                        )}
                    </div>
                )}
            </div>
        </HeadStyled>
    )
}

Head.propTypes = {
    className: PropTypes.string,
    title: PropTypes.string,
    titleTagComponent: PropTypes.elementType,
    lead: PropTypes.string,
    buttonSearchTitle: PropTypes.string,
    buttonSearchLink: PropTypes.string,
    buttonSearchAction: PropTypes.func,
    buttonHomepageLink: PropTypes.string,
    buttonHomepageTitle: PropTypes.string,
    buttonHomepageAction: PropTypes.func
}

Head.defaultProps = {
    className: undefined,
    title: undefined,
    titleTagComponent: 'h1',
    lead: undefined,
    buttonSearchTitle: undefined,
    buttonSearchLink: undefined,
    buttonSearchAction: () => {},
    buttonHomepageTitle: undefined,
    buttonHomepageLink: '/',
    buttonHomepageAction: () => {}
}

export default withErrorBoundary(Head, {
    FallbackComponent: () => null,
    onError(error, componentStack) {
        // eslint-disable-next-line no-console
        console.error('[ErrorPageHead]: ', error, componentStack)
    }
})
