import { toRem, toRems } from '../../../../helpers/theme'

/* eslint-disable quote-props, no-dupe-keys, max-len */
const style = ({ theme }) => ({
    paddingTop: toRem(53),
    '& .errorPageHead_title': {
        margin: toRems([10, 0, 16]),
        [theme.breakpoints.up('md')]: {
            margin: toRems([15, 0])
        }
    },
    '& .errorPageHead_lead': {
        fontSize: toRem(18),
        fontWeight: theme.typography.fontWeight.extraLight,
        lineHeight: 1.6,
        [theme.breakpoints.up('md')]: {
            fontSize: toRem(20)
        }
    },
    '& .errorPageHead_buttons': {
        marginTop: toRem(25),
        [theme.breakpoints.up('md')]: {
            marginTop: toRem(65)
        }
    },
    '& .errorPageHead_button': {
        marginRight: toRem(10),
        [theme.breakpoints.up('md')]: {
            marginRight: toRem(25)
        },
        '&_icon': {
            width: toRem(14),
            height: toRem(14)
        }
    }
})

export default style
