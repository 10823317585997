import styled from '@emotion/styled'
import PropTypes from 'prop-types'

import Footer from '@hmn/rtl-net-ui/components/Footer/Footer.component'
import Header from '@hmn/rtl-net-ui/components/Header/Header.component'
import SearchPanel from '@hmn/rtl-net-ui/components/Search/Panel/Panel.component'
import { NetLuna as Luna } from '@hmn/rtl-net-ui/controllers/NetLuna'

import style from './BaseLayout/BaseLayout.style'
import Content from './BaseLayout/Content.component'

const LayoutStyled = styled.main(props => ({ ...style(props) }))

function ErrorLayout({ children }) {
    return (
        <LayoutStyled>
            <Header />

            <SearchPanel />
            <Content>{children}</Content>

            <Luna theme="netDark">
                <Footer />
            </Luna>
        </LayoutStyled>
    )
}

ErrorLayout.propTypes = {
    children: PropTypes.node.isRequired
}

export default ErrorLayout
