import { useCallback, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useUIDSeed } from 'react-uid'

import { SearchActions } from '@hmn/rtl-web-core/actions'
import { Column, Row } from '@hmn/rtl-web-core/components/layout'
import { useBreakpoints, useGTM, useListElastic } from '@hmn/rtl-web-core/hooks'

import { ArticleCard, articleCardContentMarkerVariants } from '@hmn/rtl-net-ui/components/Article/components/Card'
import { CardsBlock, cardsBlockVariants } from '@hmn/rtl-net-ui/components/CardsBlock'
import { ErrorPageHead } from '@hmn/rtl-net-ui/components/ErrorPage/components'

import ErrorLayout from '../layouts/ErrorLayout.component'

function FourOhFour() {
    const uid = useUIDSeed()
    const dispatch = useDispatch()
    const [isDesktop] = useBreakpoints('md')

    const { sendDataToGTM } = useGTM()

    useEffect(() => {
        sendDataToGTM({
            eventCategory: 'Error page'
        })
    }, [sendDataToGTM])

    const config = [
        {
            variant: 'ALPHA',
            size: '1x3',
            image: isDesktop ? 'CUSTOM_SQUARE' : 'CUSTOM_3X2'
        },
        {
            variant: 'ALPHA',
            size: '1x3',
            image: isDesktop ? 'CUSTOM_SQUARE' : 'CUSTOM_3X2'
        },
        {
            variant: 'ALPHA',
            size: '1x4',
            image: 'CUSTOM_3X2'
        },
        {
            variant: 'BETA',
            size: '1x6',
            image: 'CUSTOM_SQUARE'
        },
        {
            variant: 'BETA',
            size: '1x6',
            image: 'CUSTOM_SQUARE'
        }
    ]

    const handleNavAction = useCallback(
        event => {
            event.preventDefault()

            dispatch(SearchActions.toggle())
        },
        [dispatch]
    )

    const { data: zoneList } = useListElastic(`featured`, undefined, undefined, { method: 'GET' })

    const lead =
        'Stranica koju ste tražili nije pronađena. ' +
        'Možda je uklonjena, možda joj se promijenio naziv ili je samo trenutno nedostupna.'

    return (
        <ErrorLayout>
            <Row variant="error.main">
                <Column variant="error.head">
                    <ErrorPageHead
                        label="Info"
                        title="Ups, nešto se dogodilo!"
                        lead={lead}
                        buttonHomepageTitle="Naslovnica"
                        buttonHomepageLink="/"
                        buttonSearchTitle="Pretraži"
                        buttonSearchLink="/pretrazivanje"
                        buttonSearchAction={handleNavAction}
                    />
                </Column>
                {zoneList && (
                    <Column variant="error.list">
                        <CardsBlock
                            title="Najčitanije"
                            titleTag="h3"
                            variant={cardsBlockVariants.ALPHA}
                            areaXs={`"a" "b" "c" "d" "e" "f"`}
                            areaMd={`"a b c" "a b d" "a b e"`}
                            columnsXs="1fr"
                            columnsMd="1fr 1fr 1fr"
                            count={5}
                            config={config}>
                            {zoneList.map((item, index) => (
                                <ArticleCard
                                    item={item}
                                    key={uid(item?.id || index)}
                                    href={item.sitemap?.href || '/'}
                                    hrefAs={item.sitemap?.href || '/'}
                                    contentMarkerVariant={
                                        item.type === 'gallery' || item.type === 'video'
                                            ? articleCardContentMarkerVariants[
                                                  `${item.type === 'gallery' ? 'PHOTO' : 'VIDEO'}`
                                              ]
                                            : articleCardContentMarkerVariants.NONE
                                    }
                                />
                            ))}
                        </CardsBlock>
                    </Column>
                )}
            </Row>
        </ErrorLayout>
    )
}

export default FourOhFour
